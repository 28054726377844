import * as React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"
import ProductCard from "./ProductCard"
import useQueryString from "../../hooks/useQueryString"
import * as tracker from "../../tracking/tracker"
import { compareStockStatus } from "../../util/productUtil"
import AllStockStatusType from "../../model/AllStockStatusType"
import { PrimaryButton, screenSizes } from "@social-supermarket/social-supermarket-components"
import { cleanString } from "../../util/generalUtil"
import { ProductType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  width: 100%;
  text-align: left;

  padding: 0;

  @media (max-width: ${screenSizes.largeDesktop}px) {
    padding: 0 10px;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Products = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 10px;
  margin-bottom: 60px;
  width: 100%;

  @media (max-width: ${screenSizes.tablet}px) {
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  }
`

interface PropsType {
  products: ProductType[]
  collectionName: string
  nProducts?: number
  className?: string
  hideBrand?: boolean
}

const ProductList = ({
  products,
  collectionName,
  nProducts = 24,
  className,
  hideBrand,
}: PropsType) => {
  const [stockStatus, setStockStatus] = useState<AllStockStatusType>(null)
  const [page, setPage] = useQueryString(cleanString(collectionName), 1)

  useEffect(() => {
    const init = async () => {
      trackImpressions()
    }
    init()
  }, [])

  const trackImpressions = () => {
    const start = (page - 1) * nProducts
    tracker.productImpressions(start, products.slice(start, start + nProducts), collectionName)
  }

  const loadMore = () => {
    setPage(parseInt(page) + 1)
    trackImpressions()
    tracker.loadMoreProducts()
  }

  const renderButton = page * nProducts < products.length

  return (
    <Container className={className}>
      <Products>
        {renderLoop(
          parseInt(page),
          getLiveProducts(products),
          stockStatus,
          nProducts,
          collectionName,
          hideBrand
        )}
      </Products>
      {renderButton && (
        <ButtonContainer>
          <PrimaryButton onClick={loadMore}>Load More</PrimaryButton>
        </ButtonContainer>
      )}
    </Container>
  )
}

const renderLoop = (
  page,
  products,
  stockStatus,
  nProducts,
  collectionName: string,
  hideBrand: boolean
) => {
  let rendered = []
  for (let i = 0; i < page; i++) {
    rendered = [
      ...rendered,
      ...renderProducts(i, products, stockStatus, nProducts, collectionName, hideBrand),
    ]
  }
  return rendered
}

const renderProducts = (
  i: number,
  products: ProductType[],
  stockStatus: AllStockStatusType,
  nProducts: number,
  collectionName?: string,
  hideBrand?: boolean
) => {
  const start = i * nProducts
  const finish = start + nProducts

  return products
    .sort(compareStockStatus)
    .slice(start, finish)
    .map((product, i) => (
      <ProductCard
        key={product.productId}
        product={product}
        position={start + i + 1}
        collectionName={collectionName}
        hideBrand={hideBrand}
      />
    ))
}

const getLiveProducts = (products: ProductType[]): ProductType[] => {
  return products.filter(product => !product.status || product.status === "publish")
}

export default ProductList
