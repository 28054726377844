import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import { textBlack, unselectedTextGrey } from "../../constants/colors"
import { ProductTaxonomyType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  display: flex;
  font-size: 0.8em;
`
const Space = styled.div`
  height: 10px;
`
const Name = styled.p`
  margin-bottom: 0 !important;
  font-weight: bold;
  color: ${textBlack};
`
const LinkName = styled.p`
  margin-bottom: 0 !important;
`
const Parent = styled.p`
  margin-bottom: 0 !important;
`
const Slash = styled.p`
  margin-bottom: 0 !important;
  padding: 0 5px;
  color: ${textBlack};
`
const Link = styled(GLink)`
  color: ${textBlack};
  text-decoration: underline;
`

interface Props {
  className?: string
  taxonomy: ProductTaxonomyType
  linkLeaf?: boolean
}

const Breadcrumbs: FC<Props> = ({ className, taxonomy, linkLeaf = false }) => {
  const renderParent = (taxonomy: ProductTaxonomyType) => {
    if (taxonomy.parent) {
      return (
        <>
          {renderParent(taxonomy.parent)}
          <Parent>
            <Link to={`/product-category/${taxonomy.parent.slug}`}>
              {taxonomy.parent.title ? taxonomy.parent.title : taxonomy.parent.name}
            </Link>
          </Parent>
          <Slash>/</Slash>
        </>
      )
    } else {
      return null
    }
  }
  return taxonomy.parent ? (
    <Container className={className}>
      {renderParent(taxonomy)}

      {linkLeaf ? (
        <LinkName>
          <Link to={`/product-category/${taxonomy.slug}`}>
            {taxonomy.title ? taxonomy.title : taxonomy.name}
          </Link>
        </LinkName>
      ) : (
        <Name>{taxonomy.name}</Name>
      )}
    </Container>
  ) : (
    <Space />
  )
}

export default Breadcrumbs
